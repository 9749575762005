import { ref, watch } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useTaskList(currentUserId) {
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'program_name', label: 'program', sortable: false },
    { key: 'user_name', label: 'user', sortable: false },
    { key: 'task_name', label: 'task', sortable: false },
    { key: 'status', label: 'status', sortable: false },
    { key: 'date', label: 'due date', sortable: false },
  ]
  const perPage = ref(DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)

  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchTasks = (ctx, callback) => {
    const payloadData = {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      parentId: currentUserId,
    }
    if (searchQuery.value) {
      payloadData.userName = searchQuery.value
    }
    store
      .dispatch('app-parent/fetchTaskList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta

        callback(data)
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Task list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'Completed') return 'success'
    if (status === 'Overdue') return 'danger'
    return 'primary'
  }

  return {
    fetchTasks,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,

    resolveStatusVariant,

    refetchData,
  }
}
