<template>
  <b-row>
    <b-col
      v-for="child in userData.children"
      :key="child.id"
      cols="4"
    >
      <b-card
        no-body
        class="card-apply-job"
      >
        <b-card-header class="pb-1">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar
                size="42"
                :src="child.avatar"
              />
            </b-media-aside>
            <b-media-body>
              <h5 class="mb-0">
                {{ child.full_name }}
              </h5>
              <small class="text-muted">{{ child.extra_user_info.gender }}</small>
            </b-media-body>
          </b-media>
          <b-badge
            pill
            variant="light-primary"
          >
            AGE {{ child.extra_user_info.age }}
          </b-badge>

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :key="'edit'"
              :to="{ name: 'admin-student-profile', params: { id: child.id } }"
            >
              <span>View</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('delete', permissionSubjects.Child)"
              @click="showDeleteConfirmationModal(child)"
            >
              <span>Delete {{ camperStudent(1) }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-card-header>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import {
  BCard, BCardHeader, BRow, BCol, BBadge,
  BMedia, BMediaAside, BMediaBody, BAvatar, BDropdownItem, BDropdown,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useUsersList from '@/views/apps/user/users-list/useUsersList'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BMedia,
    BMediaAside,
    BMediaBody,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      permissionSubjects,
    }
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
  methods: {
    showDeleteConfirmationModal(user) {
      this.$store.commit('app-parent/SET_DELETABLE_USER', user)
      this.$nextTick(() => {
        this.$bvModal.show('confirmation')
      })
    },
  },
}
</script>

<style>

</style>
