<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'admin-parents'}"
        >
          Parents List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData.user">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <h2>
          Parent Profile
        </h2>
        <login-as-user
          v-if="canLoginAsUser"
          :user-id="userData.user.id"
          :user-role="userData.user.role.name"
        />
      </div>
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <user-view-user-info-card :user-data="userData" />
        </b-col>

        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <user-view-user-plan-card :user-data="userData" />
        </b-col>
      </b-row>

      <b-card>
        <user-view-user-info
          :user-data="userData"
          :validation-errors="parentUpdateValidationErrors"
          @updParentInfo="updateParentInfo"
        />
      </b-card>

      <user-children-list
        :user-data="userData"
      />

      <applications-list
        :user-data="userData"
      />

      <task-list
        :user-data="userData"
      />

      <transaction-list
        v-if="$can('read', this.permissionSubjects.Transaction)"
        :user-data="userData"
      />

      <parents-children-list
        :user-data="userData"
      />

    </template>

    <confirmation-modal
      v-if="deletableUser"
      :toggle-modal="confirmationModalHide"
      :title="'Are you sure'"
      :message="`Are you sure you want to permanently delete this user (${deletableUser.full_name})? ` +
        'All associated applications will also be removed.'"
      :is-loading="isUserDeleteLoading"
      @close-modal="confirmationModalHide = !confirmationModalHide"
      @confirm="deleteItem"
    />

  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import store from '@/store'
import router from '@/router'
import userStoreModule from '@/views/admin/parents/parentsStoreModule'
import UserViewUserInfoCard from '@/views/admin/parents/parent-profile/ParentViewUserInfoCard.vue'
import UserViewUserPlanCard from '@/views/admin/parents/parent-profile/ParentViewUserPlanCard.vue'
import UserViewUserInfo from '@/views/admin/parents/parent-profile/ParentViewUserInfo.vue'
import UserChildrenList from '@/views/admin/parents/parent-profile/ParentChildrenList.vue'

import ApplicationsList from '@/views/admin/parents/parent-applications/ApplicationsList.vue'
import TransactionList from '@/views/admin/transactions-v2/transactions-list/TransactionsList.vue'
import TaskList from '@/views/admin/parents/parent-tasks/TaskList.vue'
import ParentsChildrenList from '@/views/components/parents-children-list/ParentsChildrenList.vue'
import useParentProfile from '@/views/admin/parents/parent-profile/useParentProfile'
import LoginAsUser from '@/views/components/login-as-user/LoginAsUser.vue'
import { permissionSubjects } from '@/libs/acl/constants'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'

export default {
  components: {
    ConfirmationModal,
    BRow,
    BCol,
    BCard,
    BAlert,
    BLink,

    // Local Components
    UserViewUserInfo,
    UserViewUserInfoCard,
    UserViewUserPlanCard,

    UserChildrenList,

    ApplicationsList,
    TransactionList,
    TaskList,
    ParentsChildrenList,
    LoginAsUser,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-parent'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      updateParent,
      deleteUser,
      fetchParent,
    } = useParentProfile()

    return {
      fetchParent,
      updateParent,
      deleteUser,
    }
  },
  data() {
    return {
      parentID: router.currentRoute.params.id,
      parentUpdateValidationErrors: [],
      permissionSubjects,
      confirmationModalHide: false,
      isUserDeleteLoading: false,
      userData: ref(null),
    }
  },
  computed: {
    ...mapGetters({
      deletableUser: 'app-parent/getDeletableUser',
    }),
    canLoginAsUser() {
      return this.$can('read', this.permissionSubjects.LoginAsParent)
    },
  },
  async mounted() {
    await this.prepareParentInfo()
  },
  methods: {
    async prepareParentInfo() {
      if (this.parentID) {
        await this.fetchParent(this.parentID).then(response => {
          this.userData = response
        })
      }
    },
    updateParentInfo() {
      this.parentUpdateValidationErrors = []
      this.updateParent(this.userData)
        .then(response => {
          if (response.status === 422) {
            const validationErrors = Object.values(response.data.errors)
            this.parentUpdateValidationErrors = validationErrors.flat()
          }
        })
    },
    async deleteItem() {
      if (this.deletableUser.id) {
        try {
          this.isUserDeleteLoading = true
          await this.deleteUser(this.deletableUser.id)
          this.$store.commit('app-parent/SET_DELETABLE_USER', null)
          await this.prepareParentInfo()
        } finally {
          this.isUserDeleteLoading = false
        }
      }

      this.confirmationModalHide = !this.confirmationModalHide
    },
  },
}
</script>

<style>

</style>
