<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <template #header>
        <h4
          class="d-flex align-items-center mb-0"
        >
          Children
        </h4>

        <div>
          <b-button
            v-b-modal.combine-families-modal
            class="ml-1"
            variant="primary"
            :disabled="!$can('update', permissionSubjects.Child) || !$can('update', permissionSubjects.Parent)"
          >
            <span
              class="ml-25 align-middle"
            >Add To Family </span>
          </b-button>
          <b-button
            v-b-modal.add-camper-to-family-modal
            class="ml-1"
            variant="primary"
            :disabled="!$can('update', permissionSubjects.Child) || !$can('update', permissionSubjects.Parent)"
          >
            <span
              class="ml-25 align-middle"
            >Add Child </span>
          </b-button>

          <b-button
            v-b-modal.link-student-modal
            class="ml-1"
            variant="primary"
            :disabled="!$can('update', permissionSubjects.Child) || !$can('update', permissionSubjects.Parent)"
          >
            <span
              class="ml-25 align-middle"
            >Invite {{ camperStudent(1) }} By Email</span>
          </b-button>
        </div>
      </template>

      <b-table
        :items="items"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
      >

        <!-- Column: name -->
        <template #cell(name)="data">
          <b-avatar
            class="mr-1"
            size="32"
            :src="data.item.avatar"
          />
          <b-link
            :to="{ name: 'admin-student-profile', params: { id: data.item.id } }"
          >{{ data.item.full_name }}</b-link>
        </template>

        <!-- Column: email -->
        <template #cell(email)="data">
          {{ data.item.email }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-for="option in actionOptions"
              :key="option.value"
              :to="{ name: option.link, params: { id: data.item.id } }"
            >
              <span>{{ option.title }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('read', permissionSubjects.LoginAsChild)"
              @click="loginAs(data.item.id, data.item.role.name)"
            >
              <span>Log In as {{ camperStudent(1) }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('delete', permissionSubjects.Child)"
              @click="deleteFromFamily(data.item)"
            >
              <span>Remove From Family</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('delete', permissionSubjects.Child)"
              @click="showDeleteConfirmationModal(data.item)"
            >
              <span>Delete {{ camperStudent(1) }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <link-student-modal
      :user-id="userData.user.id"
    />
    <add-student-to-family-modal
      :family-id="userData.user.family_id"
      @success="fetchingCampersForAdd"
    />
    <combine-families-modal
      :family-id="userData.user.family_id"
      :user-id="userData.user.id"
      @success="fetchingCampersForAdd"
    />

    <confirmation-modal
      v-if="deletableStudent"
      :toggle-modal="confirmationModalHide"
      :title="'Are you sure'"
      :message="`Are you sure you want to permanently delete this user (${deletableStudent.full_name})? ` +
        'All associated applications will also be removed.'"
      :is-loading="isUserDeleteLoading"
      @close-modal="confirmationModalHide = !confirmationModalHide"
      @confirm="deleteItem"
    />
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BLink,
  BAvatar,
  BButton, BDropdownItem, BDropdown,
} from 'bootstrap-vue'
import { loginAs } from '@core/mixins/loginAs'
import LinkStudentModal from '@/views/components/parents-children-list/LinkStudentModal.vue'
import AddStudentToFamilyModal from '@/views/components/parents-children-list/AddStudentToFamilyModal.vue'
import CombineFamiliesModal from '@/views/components/parents-children-list/CombineFamiliesModal.vue'
import useParentsChildrenList from '@/views/components/parents-children-list/useParentsChildrenList'
import { permissionSubjects } from '@/libs/acl/constants'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'

export default {
  components: {
    ConfirmationModal,
    BDropdown,
    BDropdownItem,
    BCard,
    BTable,
    BLink,
    BAvatar,
    BButton,
    LinkStudentModal,
    AddStudentToFamilyModal,
    CombineFamiliesModal,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  mixins: [loginAs],
  data() {
    return {
      items: [],
      permissionSubjects,
      confirmationModalHide: false,
      isUserDeleteLoading: false,
      deletableStudent: null,
    }
  },
  setup() {
    const {
      fetchChildren,
      deleteUser,
      deleteUserFromFamily,
      tableColumns,
      actionOptions,
    } = useParentsChildrenList()

    return {
      fetchChildren,
      deleteUser,
      deleteUserFromFamily,
      tableColumns,
      actionOptions,
    }
  },
  async created() {
    await this.fetchingCampersForAdd()
  },
  methods: {
    async deleteItem() {
      if (this.deletableStudent.id) {
        try {
          this.isUserDeleteLoading = true
          await this.deleteUser(this.deletableStudent.id)
            .then(() => {
              this.fetchingCampersForAdd()
            })
          this.deletableStudent = null
        } finally {
          this.isUserDeleteLoading = false
        }
      }

      this.confirmationModalHide = !this.confirmationModalHide
    },
    async deleteFromFamily(student) {
      if (student.family_member_id) {
        try {
          this.isUserDeleteLoading = true
          await this.deleteUserFromFamily(student.family_member_id)
            .then(() => {
              this.fetchingCampersForAdd()
            })
        } finally {
          this.isUserDeleteLoading = false
        }
      }

      this.confirmationModalHide = !this.confirmationModalHide
    },
    showDeleteConfirmationModal(student) {
      this.deletableStudent = student
      this.$nextTick(() => {
        this.$bvModal.show('confirmation')
      })
    },
    async fetchingCampersForAdd() {
      await this.fetchChildren(this.userData.user.family_id)
        .then(response => {
          this.items = response
        })
    },
  },
}
</script>

<style lang="scss">

</style>
